import React from "react";
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    CardGroup,
    Card,
    CardBody,
    Col,
    Row,
} from "mdbreact";
import './home.css';


export default class cardList extends React.Component {
    constructor(props){
        super(props);

        this.state={
            orders: props.orders || [],
            sortedOrders: props.sortedOrders || [],
        }
    }

    static contextTypes={
        accountOrders: PropTypes.array,
        ordersLoaded:PropTypes.bool
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.sortedOrders !== this.state.sortedOrders){
            this.setState({sortedOrders: nextProps.sortedOrders});
            return true;
        }
        return false;
    }

    titleCase(name){
        if(name) {
            return name.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ');
        }
    }

    renderNameRouteButton(order) {

        return (
            <div>
            <span style={{fontSize: '16px', fontWeight: "bold"}}>
                {order.Patient ? this.titleCase(order.Patient) : "Patient Information Loading"}
                <br/>
                 </span>
                <span style={{fontSize: '14px'}}>
                {order.Facility ? this.titleCase(order.Facility) : "Facility Information Loading"}
                    <br/>
                 </span>
            </div>


        )
    }

    renderOrderAge(d){
        if(d == null){
            return 0;
        }

        let a = new Date().getTime(),
            oneDay = 1000*60*60*24,
            c = a - Date.parse(d),
            v = Math.round(c/oneDay);

        return(v.toLocaleString())

    }

    renderCardGroup() {
        return (
            <CardGroup className={'clCardGroup'} deck>
                {this.state.sortedOrders.map((item, idx) => {
                    return this.renderCard4(item, idx);
                })}
            </CardGroup>
        );
    }

    renderCard4(item, idx){
        // TODO
       if(!item.Facility){
           return
       }

        return(
            <div key={idx} className={'clCardSizing'}>
                <Col md={'12'} style={{paddingLeft: 0, paddingRight:0}}>
                    <ButtonGroup className={'clCardButtonGroup'}>
                        <Button
                            disabled={true}
                            className={'ageButtonBackgroundColor clCardAgeButton fullOpacity'}
                        >
                            {this.renderOrderAge(item.Last_Order_Date)}
                            <br/>
                            <span style={{fontSize:'12px'}}>
                                Days
                            </span>
                        </Button>
                        <Button
                            disabled={true}
                            className={'statusButtonDelivery  clStatusButton fullOpacity'}
                        >
                             <span style={{fontSize: '16px'}}>
                                 $ {item.amount.toLocaleString()}
                                 {/*$ {item.amount}*/}

                                 <br/>
                             </span>
                            <span style={{fontSize: '14px'}}>
                                  Serial Number
                                 <br/>
                            </span>
                            <span style={{fontSize: '16px'}}>
                                  {item.serial}

                             </span>
                            <br/>


                        </Button>
                    </ButtonGroup>
                </Col>
                <Card className={"nameRouteCard"}>
                    <CardBody className={"nameCardBody"}>
                        <Row>
                            <Col
                                // style={{cursor:'pointer'}}
                            >
                                {this.renderNameRouteButton(item)}
                            </Col>
                        </Row>
                    </CardBody>

                </Card>



            </div>


        )
    }

    render(){
        return(
            <div>
                {this.renderCardGroup()}
            </div>
        )

    }

}