import api from './api';

class AuthService {
    login(credentials){

        let company = 'rehabmedical';

        return new Promise((res, reject)  => {

         let baseURL = api.buildURL(company);
         ///    let baseURL = api.buildURL('dev');
        fetch(baseURL + '/token', {
            method: 'post',
            headers:{
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                username: credentials.username,
                password: credentials.password
            })
        })
        .then((response) => {
                if(response.status >= 200 && response.status < 300){
                    return response.json();
                }
                else{
                    reject('Unable to login.  Check your credentials.');
                }
            })
            .then(response => {
                ///response.company = 'demo';
                // let x = response;
                response.company = company;
                window.sessionStorage.setItem('tokenCredentials', JSON.stringify(response));
                res(response);
            })
            .catch((err) => {
                reject('Unable to login ' + err);
            })
        })
    }
    logout(cb){

    }


    async getMyUserDetails(id){
        return new Promise((res, reject) => {
            api.get('/whoami/'+id).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            })
        })

    }

}

const authService = new AuthService();

export default authService;