import React from "react";
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
    Button,
    CardHeader,
    Col,
    Container,
    Fa,
    Input, MDBIcon,
    Row,
    Spinner,
    ToastContainer
} from "mdbreact";
import './home.css';
import OrderService from "../Security/OrderService/orderService";
import CardList from "./cardList";
import {MenuItem, TextField} from "@material-ui/core";
import corkLogo from "../../Images/Cork-EMS-LOGO-C.png";
// import corkLogo from "../../Images/corkicon.png";



class Home extends React.Component {
    static contextTypes = {
        logOutFunction: ()=>{},
        ordersLoaded: PropTypes.bool,
        accountOrders: PropTypes.array,
        currentAccount: PropTypes.object,
        currentUser: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            sortedOrders: [],
            filteredOrders: [],
            isLoaded: true,
            userContextLoaded: props.userContextLoaded,
            searchName: "",
            searching: false,
            cardLoaded: false,
            facilities:[{name: "Loading Facilities", value: -10}],
            selectedFacility: {
                name: "",
                value: null,
            }
        };
    }

    componentDidMount() {
        this.retrieveOrders();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.userContextLoaded !== this.state.userContextLoaded) {
            this.setState({userContextLoaded: nextProps.userContextLoaded});
        }
    }


    getFacilities(ords){
        let facilities = [];

        ords.map(ord => {
            if (facilities.findIndex(i => i.name === ord.Facility.trim()) === -1 ){
                facilities.push({name: ord.Facility.trim(), value: facilities.length+1 })
            }
        });

        this.setState({
            facilities: facilities
        })

        return facilities;

    }

    retrieveOrders() {

        return OrderService.getOrdersByAccounts().then(res => {
            res = res.filter(order => order.Pump_Status !== "Returned");
            this.getFacilities(res)
            let ord = this.filterOrders(res)

            this.setState({
                orders: res,
                sortedOrders: ord,
                isLoaded: true,
                cardLoaded: true,
            });

        }).catch(() => {
            //handle error...BC
        })
    }

    searchOrders(filtered) {

        let filterSearched = filtered.filter(ord => ord.Patient.toLowerCase().includes(this.state.searchName.toLowerCase()) );

        return filterSearched;
    }

    filterOrders(allOrders) {
        let filtered = allOrders || this.state.orders;
        if(this.state.selectedFacility.name){
            filtered = (filtered.filter(order => order.Facility.trim() === this.state.selectedFacility.name))
        }
        // To show the newest orders first
        filtered.sort((a, b) => b.Last_Order_Date.localeCompare(a.Last_Order_Date));

        filtered = this.searchOrders(filtered);

        this.setState({
            sortedOrders: filtered,
            isLoaded: true,
            cardLoaded: true,
        })
        return filtered
    }

    formatDate(date) {
        return (new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date))
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div className={'dbLoadingSpinner'}>
                    <Spinner multicolor/>
                </div>
            </Container>
        )
    }

    renderCardListOrSpinner() {
        if (this.state.cardLoaded)
            return (
                <div>
                <CardList orders={this.state.orders}
                          sortedOrders={this.state.sortedOrders}
                          selectedFacility={this.state.selectedFacility}
                />
                    <div className={"fixed-bottom downloadCsvDiv"}>
                    <Button
                        id={"my-element"}
                        className={"downloadCsvButton"}
                        floating
                        size="sm"
                        color={"indigo"}

                        data-tip={"Download CSV"}
                        onClick={() => this.clientCSV()}
                    >
                        <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                    </Button>
                        <ReactTooltip/>
                    </div>

                </div>

            )
        return (
            this.renderLoadingSpinner()
        )
    }
    setFacility(target) {

        let facilities = this.state.facilities;
        facilities = facilities.find(fac => fac.value === target.value)
        this.setState({
            selectedFacility : facilities
        })
    }

    clientCSV() {
        let stateData = this.state.sortedOrders;
        let result = "",
            ctr = 0,
            keys = ["Patient", "Facility", "Order_Id",
            "Last_Order_Date",  "Serial", "Amount"
            // ,"Patient_ID" "Pump_Status","Order_type",
            ],
            columnDelimiter = ",",
            lineDelimiter = "\n";

        if (stateData == null || !stateData.length) {
            return null;
        }

        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        stateData.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (key === "notes" && item[key] != null) {
                    item[key] = item[key].replace(/,/g, " ");
                }

                if (key === "Order_Id") {
                    result += item["id"] === null || item["id"] === undefined ? "" : item["id"];
                }

                if (key === "Order_type") {
                    result += item["order_type"] === null || item["order_type"] === undefined ? "" : item["order_type"];
                }
                if (key === "Serial") {
                    result += item["serial"] === null || item["serial"] === undefined ? "" : item["serial"];
                }
                if (key === "Amount") {
                    result += item["amount"] === null || item["amount"] === undefined ? "" : item["amount"];
                }
                else {
                    result += item[key] === null || item[key] === undefined ? "" : item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, "EquipmentStatus.csv");
    }

    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {

        const {currentUser} = this.context;
        const {logOutFunction} = this.context;
        if (!currentUser.id) {
            return (<Redirect to='/login'/>);
        }

        if (this.state.isLoaded === false || this.state.userContextLoaded === false) {
            return this.renderLoadingSpinner();
        }
        let facilities = this.state.facilities;

        return (
            <div className={'dbMainContainer'}>
                <CardHeader className={'searchBanner'}>
                    <div className={'patientListNameBanner'}>

                        <Row style={{justifyContent: "center"}} className={"fitRow"}>
                            <Col>
                                <Button className={'logoButton'}
                                        onClick={() => {
                                            this.retrieveOrders();
                                            this.setState({
                                                searchName: "",
                                                cardLoaded: false,
                                                selectedFacility: {
                                                    name: "",
                                                    value: null,
                                                }
                                            });
                                        }}
                                        size={'md'}
                                >
                                    <img src={corkLogo} width='300' alt={"Home Link"}/>
                                </Button>
                            </Col>
                            <Col className={"uiOutlines"}>
                                <TextField
                                    sx={{
                                        color: "red"
                                    }}
                                    className={"uiDropdown"}
                                    id="outlined-select-status"
                                    select
                                    size="small"
                                    fullWidth
                                    label="Facility"
                                    value={this.state.selectedFacility.value || ""}
                                    onChange={(e) => {
                                        this.setFacility(e.target);
                                        this.retrieveOrders();
                                    }}

                                >
                                    {facilities.map((option) => {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Col>
                            {/*<Col></Col>*/}
                            <Col>
                                <Input
                                    label={'Patient Name'}
                                    value={this.state.searchName}
                                    onChange={(e) => this.setState({searchName: e.target.value})}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.setState({
                                                cardLoaded: false,
                                            })
                                            this.filterOrders()
                                        }
                                    }}
                                />
                            </Col>
                            <Col className={"searchRow"}>
                                {/*<Row>*/}
                                <Button className={'searchBoxPt'}
                                        onClick={() => {
                                            this.setState({
                                                cardLoaded: false,
                                            })
                                            this.filterOrders()
                                        }}
                                        size={'md'}>
                                    Search
                                </Button>
                                <Button className={'searchBoxPt'}
                                        onClick={() => {


                                            this.setState({
                                                searchName: "",
                                                sortedOrders: this.state.orders,
                                                selectedFacility: {
                                                    name: "",
                                                    value: null,
                                                }
                                            });


                                        }
                                        }
                                        size={'md'}>
                                    Clear
                                </Button>

                                {/*</Row>*/}
                            </Col>
                            <Col className={"searchRow"} style={{textAlign: "right"}}>


                                {/*<Button*/}
                                {/*    onClick={() => {*/}
                                {/*        logOutFunction();*/}
                                {/*        window.sessionStorage.removeItem('currentUser');*/}
                                {/*        this.setState({loggedIn: false, userContextLoaded: false,currentUser: null,});*/}

                                {/*    }}*/}

                                {/*    to*/}
                                {/*>*/}
                                    {/*<Redirect to='/'/>*/}
                                    <h1 className={"logOutIcon"} >
                                        <Fa icon='sign-out' onClick={() => {
                                            logOutFunction();
                                            window.sessionStorage.removeItem('currentUser');
                                            this.setState({loggedIn: false, userContextLoaded: false,currentUser: null,});

                                        }}
                                        style={{cursor:'pointer'}}></Fa>
                                    </h1>
                                {/*</Button>*/}
                            </Col>
                        </Row>
                    </div>
                </CardHeader>

                <div align="center">
                    <ToastContainer
                        hideProgressBar={false}
                        newestOnTop={true}
                        autoClose={5000}
                    />

                    {this.renderCardListOrSpinner()}
                </div>
            </div>
        )
    }


}

export default Home;

