import api from '../api';
import React from "react";

class OrderService extends React.Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    async getOrdersByAccounts(accountId){

        return new Promise((res, reject) => {

            //TODO switch back to account_id
            // let cu = JSON.parse(window.sessionStorage.getItem('currentUser'));
            // cu = JSON.parse(cu);
            // let url = '/values/'+cu.account_id;


            //TODO Remove testing function
            let cu = "";
            let url = "";
            if(window.sessionStorage.getItem('testAccountId')){
                cu = JSON.parse(window.sessionStorage.getItem('testAccountId'));
                let x = cu;
                // cu = JSON.parse(cu);
                 url = '/values/'+cu;
            }else{
                 url = '/values/'+7807;
            }






            // let url = '/values/'+7807;


            return api.get(url).then(data => {
                res(data);
            }).catch(err => {
                reject();
            })
        })
    }

    async getCurrentUser(){
        return new Promise((res, err) => {
            let creds = window.sessionStorage.getItem('currentUser');
            res(JSON.parse(creds));
        })
    }


}

const orderService = new OrderService();

export default orderService;