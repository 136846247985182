import React from "react";
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import AuthService from '../Security/authService';
import {
    Button,
    Card,
    CardBody,
    Input,
    Container, Spinner,

} from "mdbreact";

import './login.css';

// import CorkLogo from "../Images/corkiconalt.png";
// import CorkLogo from "../../Images/corkicon.png";
import CorkLogo from "../../Images/Cork-EMS-LOGO-C.svg";

// import userService from "../Security/UserService/userService";

export default class login extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: '',
            loggedIn: false,
            error: '',
            currentUser: {},
            resetModalOpen: false,
            newPw:"",
            retypePw:"",
            pin:"",
            timer: false,
        }
    }

    static contextTypes= {
        currentUser: PropTypes.object,
        userFunction: () => {},
    };

    componentDidMount() {
        const {currentUser} = this.context;
        if(currentUser.id){
            this.getUserDetails(currentUser.id)
        }
    }
    checkPasswordExpiration(user){
        const {userFunction} = this.context;
        this.setState({ loggedIn: true });
        userFunction(user);

        // const {userFunction} = this.context,
        //     time = new Date().getTime();
        //
        // //the current password needs reset and the user can't login
        // if(user.passwordResetDate < time && time < (user.passwordResetDate + 172800000)){
        //     //if right now is greater than reset date but less than reset date + 2 days...BC
        //     this.setState({resetModalOpen: true});
        // }
        // else if( new Date().getTime() > user.passwordResetDate + 172800000){
        //     //if right now is greater than password reset date + 2...BC
        //     this.setState({
        //         error:'Your account has expired.  Please contact your sales rep.'
        //     });
        //
        //
        // }
        // else{
        //     //now should be any time greater than 2 days for the password reset date...BC
        //     this.setState({ loggedIn: true });
        //     userFunction(user);
        // }

    }

    checkRole(user){
        let permissions = false;

        switch (user.role){
            case 'THIRD_PARTY_USER':
            case 'ADMIN':
            case 'SYSTEM_ADMIN':
                permissions = true;
                break;
            default:
                permissions = false;
                break;
            }

       if(!permissions){
           this.setState({error: "You do not have permissions to view this app"});
           return;
       }
       this.checkPasswordExpiration(user)
    }

    //Attempt to log the user into the service
    validateAndLogin(evt){

        evt.preventDefault();
        AuthService.login(this.state).then((res) =>{
            //successfully logged in...BC

           this.getUserDetails(res.Id)
        }).catch(err => {
            //failed to login...BC
            this.setState({error: err});
        });



    }
    getUserDetails(user){
        AuthService.getMyUserDetails(user).then(user => {
            this.setState({currentUser: user});
            window.sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.checkRole(user);
            this.startLogInTimer();
        })
    }

    startLogInTimer(){
        setTimeout(() =>this.setState({timer: true}), 500)
    }
    redirectToHome(){
        if(this.state.timer) {
            return (<Redirect to='/orders'/>);
        }
        return (
        <Container className="mt-5">
            <div className={'dbLoadingSpinner'}>
                <Spinner multicolor/>
            </div>
        </Container>
        )
    }

    render(){
        if(this.state.loggedIn === true){
            return this.redirectToHome();
        }
        return(
            <div className={'loginDiv'}>
                <Card className={'w-25 loginCard'}>
                <form className={'dfd'} onSubmit={this.validateAndLogin.bind(this)}>
                    <img  src={CorkLogo}  className={"img-fluid loginPageImage"} alt={"Cork-Seating-Portal"}/>
                    <CardBody>
                    <div className={"userPassDiv"}>
                        <Input onChange={ (evt) => this.setState({ username:evt.target.value })} type='text' label="Username" icon="user-circle"/>
                        <br/>
                        <Input onChange={ (evt) => this.setState({ password:evt.target.value })} label="Password" icon="lock" group type="password" validate/>
                    </div>
                    <div className="text-center">
                        <h1 className={'loginPageErrorText'}>{this.state.error}</h1>
                        <Button type='submit' className={'loginButton'}>
                           LOGIN
                        </Button>

                    </div>
                    </CardBody>
                </form>
                </Card>
            </div>

        )
    }
}


