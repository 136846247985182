import React, {Component} from "react";
import PropTypes from 'prop-types';
import {BrowserRouter as Router} from "react-router-dom";
import {Input, MDBFooter, MDBToastContainer} from "mdbreact";
import backgroundImage from "../src/Images/CM-EMS-BKGD-LG-01.jpg";
import backgroundImagesm from "../src/Images/CM-EMS-BKGD-SM-01.jpg";

import "./index.css";
import Routes from "./Routes";
import AuthService from "./Seating/Security/authService";



const AppContext = React.createContext({});

class App extends Component {
    constructor(props) {
        super(props);
        let creds,
            acc = {};

        creds = window.sessionStorage.getItem('currentUser');

        if(creds && creds !== "undefined"){
            creds = JSON.parse(creds);
            acc = creds["account"] || {};
        }

        this.state = {
            testAccountId: '',
            currentUser: creds || {},
            currentAccount: acc,
            userContextLoaded: false,
            ordersLoaded: false,
        };
    }

    static childContextTypes = {
        userFunction: () => {},
        logOutFunction: () => {},
        accountFunction: () => {},
        currentUser: PropTypes.object,
        currentAccount: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        ordersLoaded: PropTypes.bool,
    };

    getChildContext() {
        return {
            currentAccount: this.state.currentAccount,
            logOutFunction: this.logOutFunction.bind(this),
            userFunction: this.setUserFunction.bind(this),
            accountFunction: this.setAccountFunction.bind(this),
            currentUser: this.state.currentUser,
            insuranceTypes: this.state.insuranceTypes,
            insuranceSubTypes: this.state.insuranceSubTypes,
            ordersLoaded: this.state.ordersLoaded
        }
    };

    componentDidMount() {
        //only want to try to get the context stuff if the user is logged in.
        if (this.state.currentUser.id) {
            this.getAll();
        }
    }

    getAll() {
        this.getWhoAmI();
    }

    logOutFunction(){
        this.setState({
            currentAccount: {},
            currentUser: {},
            userContextLoaded: false,
        });
        window.sessionStorage.clear();
    }
    setUserFunction(user) {
        let account = user["account"] || {};

        this.setState({
            currentAccount: account,
            currentUser: user,
            userContextLoaded: true,
        });

        this.getAll();
    };

    setAccountFunction(account){
        //if the user is an admin, allow them to change the account they want to see orders for

        this.setState({
            currentAccount: account
        });

    }

    getWhoAmI() {
        let ucl = true;
        let creds =   JSON.parse(window.sessionStorage.getItem('tokenCredentials'));

        AuthService.getMyUserDetails(creds.Id).then(user => {
            //need to do this check because if the user logs in with expired credentials we don't want them to see the navbar...BC
            // if(user.passwordResetDate + 172800000 < new Date().getTime()){
            //     ucl = false;
            // }

            this.setState({
                currentUser: user,
                currentAccount: user["account"] || {},
                userContextLoaded: ucl
            });
        }).catch(() => {
            //do nothing...BC
        })
    }

    // toggleCollapse = collapseID => () =>
    //     this.setState(prevState => ({
    //         collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    //     }));
    //
    // closeCollapse = collapseID => () =>
    //     this.state.collapseID === collapseID && this.setState({collapseID: ""});
    // renderNavBarItems() {
    //     if(window.location.pathname === "/" || window.location.pathname === "/login"){
    //         return (<div style={{display:'none'}}/>)
    //     }
    //     if (!this.state.userContextLoaded) {
    //         //don't need a navbar here.  show nothing...BC
    //         return (<div style={{display:'none'}}/>)
    //
    //     }
    //
    //     else {
    //
    //         return (<div style={{display:'none'}}/>)
    //         //Nav bar if we need to reimplement it inm the future
    //         // return (
    //         //     <div style={{marginBottom: '93px'}}>
    //         //     <Navbar style={{backgroundColor: "#000000"}} dark expand="lg" fixed="top" scrolling>
    //         //
    //         //         {/*<NavbarBrand href="/orders" style={{marginRight: "40px"}}>*/}
    //         //         {/*    <img src={Logo} width='225' className={"img-fluid"} alt={"Rehab Seating"}/>*/}
    //         //         {/*</NavbarBrand>*/}
    //         //         <NavbarBrand href="/orders">
    //         //             <img src={corkLogo} width='180' className={"img-fluid"} alt={"Home Link"}/>
    //         //         </NavbarBrand>
    //         //         <NavbarToggler
    //         //             onClick={this.toggleCollapse("mainNavbarCollapse")}
    //         //         />
    //         //         <Collapse
    //         //             id="mainNavbarCollapse"
    //         //             isOpen={this.state.collapseID}
    //         //             navbar
    //         //         >
    //         //             <NavbarNav right>
    //         //                 {/*<NavItem>*/}
    //         //                 {/*    <NavLink*/}
    //         //                 {/*        onClick={this.closeCollapse("mainNavbarCollapse")}*/}
    //         //                 {/*        to="/orders">*/}
    //         //                 {/*        <h4>*/}
    //         //                 {/*            Open Orders*/}
    //         //                 {/*        </h4>*/}
    //         //                 {/*    </NavLink>*/}
    //         //                 {/*</NavItem>*/}
    //         //
    //         //                 {/*<div style={{paddingRight: '50px'}}/>*/}
    //         //
    //         //                 {/*<NavItem>*/}
    //         //                 {/*    <NavLink*/}
    //         //                 {/*        onClick={this.closeCollapse("mainNavbarCollapse")}*/}
    //         //                 {/*        to="/patientList">*/}
    //         //                 {/*        <h4>*/}
    //         //                 {/*            Patient Archive*/}
    //         //                 {/*        </h4>*/}
    //         //                 {/*    </NavLink>*/}
    //         //                 {/*</NavItem>*/}
    //         //
    //         //                 {/*<div style={{paddingRight: '50px'}}/>*/}
    //         //
    //         //                 <NavItem>
    //         //                     <NavLink
    //         //                         onClick={() => {
    //         //                             this.closeCollapse("mainNavbarCollapse");
    //         //                             window.sessionStorage.setItem('currentUser', "");
    //         //                             this.setState({loggedIn: false, userContextLoaded: false});
    //         //                         }}
    //         //                         to={"/login"}
    //         //                     >
    //         //                         <h4>
    //         //                             <Fa icon='sign-out'/>
    //         //                         </h4>
    //         //                     </NavLink>
    //         //                 </NavItem>
    //         //             </NavbarNav>
    //         //         </Collapse>
    //         //     </Navbar>
    //         //     </div>
    //         // )
    //     }
    // }

    render() {
        //more navbar
        // const overlay = (
        //     <div
        //         id="sidenav-overlay"
        //         style={{backgroundColor: "transparent"}}
        //         onClick={this.toggleCollapse("mainNavbarCollapse")}
        //     />
        // );

        return (
            <AppContext.Provider value={this.state}>
                <Router>
                    <div className={"flyout grey lighten-2 mainBackground"}
                         style={this.state.currentUser.id ?{} : {backgroundImage:`url(${backgroundImage})`}}>
                        {/*style={{backgroundImage:`url(${backgroundImage})`}}>*/}

                        <div>
                            <MDBToastContainer
                                hideProgressBar={false}
                                newestOnTop={true}
                                autoClose={5000}
                            />

                                {/*{this.renderNavBarItems()}*/}


                            {/*{this.state.collapseID && overlay}*/}
                            <main>
                                <Routes userContextLoaded={this.state.userContextLoaded}/>
                            </main>
                        </div>

                        <MDBFooter   color={'black'}>
                            {/*TODO REMOVE */}


                            <p className="footer-copyright mb-0 py-1 text-center testID">
                                {/*TODO Remove input and classname*/}
                                <Input
                                    className={"TestAccountId"}
                                    label={''}
                                    value={this.state.testAccountId}
                                    onChange={(e) => this.setState({testAccountId: e.target.value})
                                    }
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            window.sessionStorage.setItem('testAccountId', this.state.testAccountId);
                                        }
                                    }}
                                />
                                &copy; {new Date().getFullYear()} Cork Medical
                            </p>
                        </MDBFooter>

                    </div>

                </Router>
            </AppContext.Provider>
        );
    }
}

export default App;
// export const AppContextConsumer = AppContext.Consumer;